import $ from 'jquery';

/** import local dependencies */
import './webflow.js';
import Headroom from "headroom.js";
//import 'slick-carousel';

/**
 * Anonymous function is autoexecutable.
 */

(function () {

  var myElement = document.querySelector("header");

  var options = {
    offset : 150,
    tolerance : 0,
  };
  var headroom  = new Headroom(myElement, options);
  headroom.init();

  function loadTabLabel(){
    var activeTab = $('.w-tab-link.w--current').text();
    var trimActiveTab = $.trim(activeTab);
    $('.dropdown-text').html(trimActiveTab);
  }

  loadTabLabel();

  $('.w-tab-link').on('click', function(){
    var tabLabel = $(this).text();
    var trimLabel = $.trim(tabLabel);
    $('.dropdown-text').html(trimLabel);
  });

})();
